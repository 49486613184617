import React, { CSSProperties, FC, useEffect, useRef, useState } from "react";
import style from "./style.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
// import "reactjs-popup/dist/index.css";
// import Popup from "reactjs-popup";
import { logout } from "../../store/slices/user";
import appConfig from "../../util/appConfig";
import transformHexToRgba from "../../util/transformHexToRgba";

interface IHeaderSelect {
  customStyle?: CSSProperties;
}

const links = [
  {
    link: "/kassenzeichen-verwaltung",
    text: "Kassenzeichen Verwaltung",
    isActive: ["lra", "calw"].includes(appConfig.logo),
  },
];

export const HeaderSelect: FC<IHeaderSelect> = ({ customStyle }) => {
  const ref = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    objectIdUser: { vorname, nachname },
    defaultUserObject,
    // : { hausnr, hausnrzusatz, strassebez, ortsteilbez, plz },
  } = useAppSelector((state) => state.user);

  const [open, setIsOpen] = useState(false);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (ref) {
      document.addEventListener("click", handleClickOutside);
      // document.addEventListener("mouseenter", handleClickOutside);
      document.addEventListener("mouseleave", handleClickOutside);
      return () => {
        document.removeEventListener("click", handleClickOutside);
        // document.removeEventListener("mouseenter", handleClickOutside);
        document.removeEventListener("mouseleave", handleClickOutside);
      };
    }
  }, [ref]);

  return (
    <div ref={ref}>
      <div
        className={style.valueContainer}
        onClick={() => {
          setIsOpen(!open);
        }}
      >
        <div className={style.name}>
          {nachname ? nachname + ", " : ""}
          {vorname ? vorname : ""}
        </div>
        <div className={style.address}>
          {defaultUserObject?.strassebez ? defaultUserObject?.strassebez : ""}{" "}
          {defaultUserObject?.hausnr ? defaultUserObject?.hausnr : ""}{" "}
          {defaultUserObject?.hausnrzusatz
            ? defaultUserObject?.hausnrzusatz
            : ""}
        </div>
        <div className={style.address}>
          {defaultUserObject?.plz ? defaultUserObject?.plz : ""}{" "}
          {defaultUserObject?.ortsteilbez ? defaultUserObject?.ortsteilbez : ""}
        </div>
      </div>
      {/* <div
        onClick={() => {
          setIsOpen(!open);
        }}
        // onMouseEnter={() => {
        //   setIsOpen(true);
        // }}
        // onMouseLeave={() => {
        //   setIsOpen(false)
        // }}
      >
        Toggle
      </div> */}
      {open ? (
        <div
          className={style.linksWrapper}
          style={{
            position: "absolute",
            top: "65px",
            right: 0,
            borderColor: transformHexToRgba("#757575", "0.3"),
          }}
        >
          <>
            {links
              .filter((el) => el.isActive)
              .map((el) => {
                return (
                  <Link
                    to={el.link}
                    className={style.link}
                    key={el.link}
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    {el.text}
                  </Link>
                );
              })}
            <div
              className={style.footerDivider}
              style={{
                // margin: "10px 0",
                width: "100%",
                height: "1px",
                backgroundColor: transformHexToRgba("#757575", "0.3"),
              }}
            />
            <div
              className={style.link}
              onClick={async () => {
                dispatch(
                  await logout({
                    navigate: () => {
                      navigate("/");
                    },
                  })
                );
                setIsOpen(false);
              }}
            >
              Ausloggen
            </div>
          </>
        </div>
      ) : null}
      {/* {open ? (
        <Menu
          mode="vertical"
          // openAnimation="slide-up"
          // triggerSubMenuAction={"click"}
          style={{}}
          ref={ref}
          className={style.container}
        >
          <MenuItem>1</MenuItem>
          <MenuItem>2</MenuItem>
        </Menu>
      ) : null} */}
      {/* <Popup
        trigger={
          <button
            type="button"
            style={{
              border: "none",
              display: "flex",
            }}
          >
            <div className={style.valueContainer}>
              <div className={style.name}>
                {vorname ? vorname + ", ": ""}{nachname ? nachname : ""}
              </div>
              <div className={style.address}>
                {strassebez ? strassebez : ""}, {hausnr ? hausnr : ""}{" "}
                {hausnrzusatz ? hausnrzusatz : ""}
              </div>
              <div className={style.address}>
                {plz ? plz : ""}, {ortsteilbez ? ortsteilbez : ""}
              </div>
            </div>
          </button>
        }
        position={"bottom center"}
        on={["hover", "click"]}
        arrow={false}
        className={style.ewq}
        open={true}
      >
        <>
          <div className={style.linksWrapper}>
            {links
              .filter((el) => el.isActive)
              .map((el) => {
                return (
                  <Link to={el.link} className={style.link} key={el.link}>
                    {el.text}
                  </Link>
                );
              })}
          </div>
          <div
            className={style.footerDivider}
            style={{
              margin: "10px 0",
            }}
          />
          <div
            className={style.link}
            onClick={() => {
              dispatch(
                logout({
                  navigate: () => {
                    navigate("/");
                  },
                })
              );
            }}
          >
            Ausloggen
          </div>
        </>
      </Popup> */}
    </div>
  );
};
