import {
  IBehaeltervervaltungBody,
  IBehaeltervervaltungResponseBody,
} from "../../store/slices/behaelterverwaltung";
import { adminInstance, userInstance } from "../api";

const getBehaelter = ({
  gemeindeNr,
  kassenzeichen,
  objId,
}: {
  gemeindeNr: string | number;
  kassenzeichen: string | number;
  objId: string | number;
}) => {
  return userInstance
    .get<IBehaeltervervaltungBody[]>("/behaelterverwaltung", {
      params: {
        gemeindeNr,
        kassenzeichen,
        objId,
      },
    })
    .then((res) => res.data);
};

const addBehaelter = ({
  quantity,
  type,
  volume,
  additionalBin,
  additionalBinVolume,
  gemeindeNr,
  objektId,
  additionalBioBinQuantity,
  selectedMonth,
  behRhythmus
}: {
  type: string;
  volume: string;
  quantity: number;
  additionalBin?: boolean;
  additionalBinVolume?: string;
  gemeindeNr: string | number;
  objektId: string | number;
  selectedMonth?: { [key: number]: string[] };
  additionalBioBinQuantity?: number;
  behRhythmus?: string;
}) => {
  return userInstance
    .post<IBehaeltervervaltungBody[]>("/behaelterverwaltung", {
      quantity,
      type,
      volume,
      additionalBin,
      additionalBinVolume,
      gemeindeNr,
      objektId,
      additionalBioBinQuantity,
      selectedMonth,
      behRhythmus,
    })
    .then((res) => res.data);
};

const defectBehaelter = ({
  id,
  reason,
  gemeindeNr,
  kassenzeichen,
  objektId,
}: {
  id: string;
  reason: string;
  gemeindeNr: string | number;
  kassenzeichen: string | number;
  objektId: string | number;
}) => {
  return userInstance
    .post<IBehaeltervervaltungBody[]>(`/behaelterverwaltung/${id}/defect`, {
      reason,
      gemeindeNr,
      kassenzeichen,
      objektId,
    })
    .then((res) => res.data);
};

const updateFilter = ({
  id,
  reason,
  gemeindeNr,
  kassenzeichen,
  objektId,
}: {
  id: string;
  reason: string;
  gemeindeNr: string | number;
  kassenzeichen: string | number;
  objektId: string | number;
}) => {
  return userInstance
    .post<{ message: string }>(
      `/behaelterverwaltung/zasatzleistung/${id}/filter`,
      {
        reason,
        gemeindeNr,
        kassenzeichen,
        objektId,
      }
    )
    .then((res) => res.data);
};

const disappearedBehaelter = ({
  id,
  disappeared,
  gemeindeNr,
  kassenzeichen,
  objektId,
}: {
  id: string;
  disappeared: boolean;
  gemeindeNr: string | number;
  kassenzeichen: string | number;
  objektId: string | number;
}) => {
  return userInstance
    .put<IBehaeltervervaltungBody[]>(`/behaelterverwaltung/${id}/disappeared`, {
      disappeared,
      gemeindeNr,
      kassenzeichen,
      objektId,
    })
    .then((res) => res.data);
};

const updateBehaelter = ({
  id,
  volume,
  gemeindeNr,
  kassenzeichen,
  objektId,
}: {
  id: string;
  volume: string;
  gemeindeNr: string | number;
  kassenzeichen: string | number;
  objektId: string | number;
}) => {
  return userInstance
    .put<IBehaeltervervaltungBody[]>(`/behaelterverwaltung/${id}`, {
      volume,
      gemeindeNr,
      kassenzeichen,
      objektId,
    })
    .then((res) => res.data);
};

const removeBehaelter = ({
  id,
  gemeindeNr,
  kassenzeichen,
  objektId,
}: {
  id: string;
  gemeindeNr: string | number;
  kassenzeichen: string | number;
  objektId: string | number;
}) => {
  return userInstance
    .delete<IBehaeltervervaltungBody[]>(`/behaelterverwaltung/${id}`, {
      params: {
        gemeindeNr,
        kassenzeichen,
        objektId,
      },
    })
    .then((res) => res.data);
};

const getAdminBehaelterverwaltung = ({
  direction,
  page,
  pageSize,
  searchValue,
  sort,
  signal,
  startDate,
  endDate,
  status,
  requestType,
}: {
  sort?: string;
  direction?: string;
  searchValue?: string;
  page?: number;
  pageSize?: number;
  signal: AbortSignal;
  startDate?: Date | null;
  endDate?: Date | null;
  status?: string[];
  requestType?: string[];
}) => {
  return adminInstance
    .get<{
      totalCount: number;
      data: IBehaeltervervaltungResponseBody[];
    }>("/admin/behaelterverwaltung", {
      params: {
        direction,
        page,
        pageSize,
        searchValue,
        sort,
        startDate,
        endDate,
        status,
        requestType,
      },
      signal,
    })
    .then((res) => res.data);
};

const getAdminBehaelterverwaltungById = (id: string) => {
  return adminInstance
    .get<IBehaeltervervaltungResponseBody>(`/admin/behaelterverwaltung/${id}`)
    .then((res) => res.data);
};

const updateStatusById = ({ id, status }: { id: string; status: string }) => {
  return adminInstance
    .put<IBehaeltervervaltungResponseBody>(`admin/behaelterverwaltung/${id}/status`, {
      status,
    })
    .then((res) => res.data);
};

const Behaelterverwaltung = {
  get: {
    getBehaelter,
    getAdminBehaelterverwaltung,
    getAdminBehaelterverwaltungById,
  },
  post: {
    addBehaelter,
    defectBehaelter,
    disappearedBehaelter,
    updateFilter,
  },
  put: {
    updateBehaelter,
    updateStatusById,
  },
  delete: {
    removeBehaelter,
  },
};

export default Behaelterverwaltung;
