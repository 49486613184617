import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { SortDescriptor } from "@progress/kendo-data-query";
import { statuses } from "../AdminSperrmuelanmeldungen";
import {
  GridCellProps,
  GridColumn,
  GridHeaderCellProps,
} from "@progress/kendo-react-grid";
import {
  AdminCheckbox,
  AdminContentWrapper,
  AdminHeader,
  Loader,
  TableGrid,
  TableLastItem,
} from "../../components";
import appConfig from "../../util/appConfig";
import { format } from "date-fns";
import { clearAdminSepaData, getAdminSepa } from "../../store/slices/sepa";

const AdminSepa = () => {
  const dispatch = useAppDispatch();
  const { isAdminLoaderActive } = useAppSelector((state) => state.admin);
  const { data, itemsCount } = useAppSelector((state) => state.sepa.admin);
  const [sort, setSort] = useState<Array<SortDescriptor>>([]);
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [datesChanged, setDatesChanged] = useState({});
  const [status, setStatus] = useState<string[]>([]);
  const [payerType, setPayerType] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [allItemsSelected, setAllItemsSelected] = useState(false);

  const onDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (end || end === null) {
      setDatesChanged({ updated: true });
    }
  };

  const changeHandler = (val: string) => {
    setValue(val);
  };

  const pageChangeHandler = (page: number) => {
    setPage(page);
  };

  const pageSizeHandler = (size: number) => {
    setPage(1);
    setPageSize(size);
  };

  const changeStatuses = (st: string[]) => {
    setStatus([...st]);
  };
  const changePayerType = (st: string[]) => {
    setPayerType([...st]);
  };

  const allItemsSelectHandler = () => {
    if (!allItemsSelected) {
      setAllItemsSelected(true);
      const ids = data.map((i) => i.id);
      setSelectedItems([...ids]);
      return;
    }
    if (allItemsSelected) {
      setAllItemsSelected(false);
      setSelectedItems([]);
      return;
    }
  };

  const selectItemHandler = (id: string) => {
    if (selectedItems.includes(id)) {
      const t = selectedItems.filter((i) => i !== id);
      return setSelectedItems([...t]);
    }

    if (!selectedItems.includes(id)) {
      return setSelectedItems((prev) => [...prev, id]);
    }
  };

  const generateSelectOptions = (type: string) => {
    switch (type) {
      case "lra": {
        return [
          { label: statuses.accepted, value: "accepted" },
          { label: statuses.inWork, value: "inWork" },
          { label: statuses.declined, value: "declined" },
          { label: statuses.error, value: "error" },
        ];
      }
      default: {
        return [
          { label: statuses.accepted, value: "accepted" },
          { label: statuses.inWork, value: "inWork" },
          { label: statuses.declined, value: "declined" },
          { label: statuses.error, value: "error" },
        ];
      }
    }
  };

  const FirstItem = (props: GridCellProps) => {
    return (
      <td
        // className="k-command-cell"
        style={
          {
            // backgroundColor: 'white',
          }
        }
      >
        <AdminCheckbox
          checked={selectedItems.includes(props.dataItem.id)}
          changeHandler={() => {
            selectItemHandler(props.dataItem.id);
          }}
        />
      </td>
    );
  };
  const FirstHeaderItem = (props: GridHeaderCellProps) => {
    return (
      <div
        style={{
          margin: "0",
          padding: "0",
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          // border: "none",
          // backgroundColor: "rgba(0, 0, 0, 0.02) !important",
        }}
        // className={style.qwe}
      >
        <AdminCheckbox
          checked={allItemsSelected}
          changeHandler={allItemsSelectHandler}
        />
      </div>
    );
  };

  useEffect(() => {
    const abortController = new window.AbortController();
    const signal = abortController.signal;

    dispatch(
      getAdminSepa({
        payerType,
        signal,
        direction: sort[0]?.dir,
        endDate,
        page,
        pageSize,
        searchValue: value,
        sort: sort[0]?.field,
        startDate,
        status,
      })
    );

    return () => {
      if (signal && abortController.abort) {
        abortController.abort();
      }
    };
  }, [sort, page, pageSize, value, datesChanged, status, payerType]);

  const selectorOptions = [
    {
      placeholder: "Bitte Status wählen",
      isActive: ["lra"].includes(appConfig.logo),
      defaultValue: undefined,
      selectOptions: generateSelectOptions(appConfig.logo).filter(
        (el) => !status.includes(el.value)
      ),
      value: status.map((el) => {
        return {
          value: el,
          label: statuses[el],
        };
      }),
      onChange: changeStatuses,
    },
    {
      placeholder: "Bitte Kontoinhaber wählen",
      isActive: true,
      defaultValue: undefined,
      selectOptions: [
        {
          value: "Eigentümer",
          label: "Eigentümer",
        },
        {
          value: "abweichendenZahlungsempfänder",
          label: "Abweichender Kontoinhaber",
        },
      ],
      value: payerType.map((el) => {
        return { value: el, label: el };
      }),
      onChange: changePayerType,
    },
  ];

  useEffect(() => {
    return () => {
      dispatch(clearAdminSepaData());
    };
  }, []);

  const generateEnvTitle = (type: string) => {
    switch (type) {
      case "lra": {
        return "PK-ID";
      }
      case "calw": {
        return "Kassenzeichen";
      }
      default: {
        return "PK-ID";
      }
    }
  };

  return (
    <div>
      <AdminHeader pageTitle={"SEPA Management"} hasBtnGroup={false} />
      <AdminContentWrapper>
        <TableGrid
          data={data.map((el) => {
            return {
              ...el,
              name: `${el.userSurname ? el.userSurname + ", " : ""} ${
                el.username
              }`,
              status: statuses[el.status],
              createdAt: format(new Date(el.createdAt), "dd.MM.yyyy, HH:mm"),
              payerType:
                el.payerType === "Eigentümer"
                  ? "Eigentümer"
                  : "Abweichender Kontoinhaber",
              street: `${el.street} ${el.homeNumber} ${
                el?.homeNumberZusatz ? el.homeNumberZusatz : ""
              }`,
            };
          })}
          sort={sort}
          sortable={true}
          onSortChange={setSort}
          items={[
            { field: "status", title: "Status" },
            { field: "createdAt", title: "Datum (Meldung)" },
            { field: "idNumber", title: generateEnvTitle(appConfig.logo) },
            { field: "name", title: "Name" },
            { field: "street", title: "Straße" },
            { field: "postCode", title: "PLZ" },
            { field: "place", title: "Ort" },
            { field: "payerType", title: "Kontoinhaber" },
          ]}
          searchInputValue={value}
          onSearchInputValueChange={changeHandler}
          withPager={true}
          page={page}
          pageSize={pageSize}
          pageChangeHandler={pageChangeHandler}
          pageSizeHandler={pageSizeHandler}
          itemsCount={itemsCount}
          onDateChange={onDateChange}
          startDate={startDate}
          endDate={endDate}
          withDatePicker
          lastItem={<GridColumn cell={TableLastItem} width="50px" />}
          selectorOptions={selectorOptions}
          firstItem={
            ["calw"].includes(appConfig.logo) ? (
              <GridColumn
                cell={FirstItem}
                width="40px"
                headerCell={FirstHeaderItem}
              />
            ) : null
          }
          excelExportData={data
            .filter((el) => selectedItems.includes(el.id))
            .map((el) => {
              return {
                ...el,
                name: `${el.userSurname ? el.userSurname + ", " : ""} ${
                  el.username
                }`,
                status: statuses[el.status],
                createdAt: format(new Date(el.createdAt), "dd.MM.yyyy, HH:mm"),
                payerType:
                  el.payerType === "Eigentümer"
                    ? "Eigentümer"
                    : "Abweichender Kontoinhaber",
                street: `${el.street} ${el.homeNumber} ${
                  el?.homeNumberZusatz ? el.homeNumberZusatz : ""
                }`,
              };
            })}
        />
      </AdminContentWrapper>
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default AdminSepa;
